<template>
  <div class="qr">
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card>
        <b-row class="align-items-center">
          <b-col class="d-flex align-items-center justify-content-start">
            <h4 class="mr-4 mb-0 text-nowrap">
              {{ $t('_navItem.QR code designer') }}
            </h4>
            <div
              class="d-flex align-items-center"
            >
              <b-dropdown
                v-b-tooltip.hover.top="$t('Zoom')"
                variant="flat"
                toggle-class="p-0"
                no-caret
                class="mr-1"
              >
                <template #button-content>
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="ZoomInIcon"
                      role="button"
                      size="20"
                      class="mr-50"
                    />
                    {{ zoom_percent }}%
                  </div>
                </template>
                <b-dropdown-form>
                  <b-form-input
                    v-model.number="zoom_percent"
                    type="range"
                    min="25"
                    size="sm"
                    max="100"
                  />
                </b-dropdown-form>
              </b-dropdown>
              <div
                style="min-height: 30px"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="show_grid"
                  size="sm"
                  class="mt-50"
                  @change="showGrid"
                >
                  {{ $t('qrLabelGrid') }}
                </b-form-checkbox>
                <template v-if="show_grid">
                  <b-form-input
                    v-model.number="grid_lines"
                    type="number"
                    min="0"
                    size="sm"
                    class="ml-2"
                    @change="showGrid"
                  />
                  <b-form-input
                    v-model="grid_color"
                    type="color"
                    size="sm"
                    class="ml-1"
                    @input="showGrid"
                  />
                </template>
              </div>
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              :disabled="isFetching || isSaving || invalidImageSize || (options.isSaved && !isLoadedFromTemplate)"
              variant="link"
              size="sm"
              class="p-0 border-0"
              @click="onClickSave"
            >
              <b-spinner
                v-if="isSaving"
                class="d-flex"
              />
              <feather-icon
                v-else
                v-b-tooltip.hover.top="$t('Save')"
                icon="SaveIcon"
                size="20"
              />
            </b-button>
            <feather-icon
              v-b-tooltip.hover.top="$t('zoneTooltipDownload')"
              icon="DownloadIcon"
              role="button"
              size="20"
              class="p-0 border-0 ml-1"
              @click="isModalQrPrint = true"
            />
            <b-button
              :disabled="isFetching || isSaving"
              variant="link"
              size="sm"
              class="p-0 border-0 ml-1"
              @click="isModalQrTemplate=true"
            >
              <feather-icon
                v-b-tooltip.hover.top="$t('qrModalTemplateTitle')"
                icon="GridIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-row v-if="options.document">
      <b-col cols="3">
        <div class="qr__sticky zindex-1">
          <b-overlay
            :show="isFetching"
            spinner-variant="primary"
          >
            <b-card>
              <h6
                style="cursor: pointer"
                @click="imageSettingsVisible = !imageSettingsVisible"
              >
                {{ $t('qrLabelImageSettings') }}
                <feather-icon
                  :icon="imageSettingsVisible ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="18"
                  style="float:right"
                />
              </h6>
              <b-collapse
                v-if="options.document.image"
                id="img-settings-collapse"
                v-model="imageSettingsVisible"
              >
                <paper-format-selector
                  v-model="options.document.image.size"
                  :label="$t('qrLabelImageSize')"
                  :imperial-first="imperialFirst"
                  size="sm"
                  class="mt-1"
                  @change="onChangeImageFormat"
                />
                <b-form-group
                  :label="$t('qrLabelImageIndent')"
                  label-size="sm"
                  class="mt-2 mb-0"
                >
                  <b-input-group
                    size="sm"
                    :append="options.document.image.size.unit"
                  >
                    <b-form-input
                      v-model.number="imageBleedOrTrim"
                      type="number"
                      :step="getStepForInput('image')"
                      @change="rerender = true"
                    />
                  </b-input-group>
                </b-form-group>
                <div
                  v-if="options.document.image.bleedOrTrim !== 0"
                  class="d-flex align-items-center"
                >
                  <div
                    class="d-flex align-items-center text-nowrap"
                    style="min-height: 38px"
                  >
                    <b-form-checkbox
                      v-model="options.document.image.crop_marks"
                      size="sm"
                      @change="rerender = true"
                    >
                      <small>{{ $t('qrLabelCropMarks') }}</small>
                    </b-form-checkbox>
                  </div>
                  <b-form-input
                    v-if="options.document.image.crop_marks"
                    v-model="options.document.image.crop_marks_color"
                    type="color"
                    size="sm"
                    class="ml-1"
                    @change="rerender = true"
                  />
                </div>
              </b-collapse>
            </b-card>
          </b-overlay>
          <b-overlay
            :show="isFetching"
            spinner-variant="primary"
          >
            <b-card>
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">
                  <feather-icon icon="LayersIcon" />
                  {{ $t('qrLabelLayers') }}
                </h6>
                <div class="d-flex">
                  +
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Add Image')"
                    icon="ImageIcon"
                    role="button"
                    class="mr-50"
                    style="margin-left: -0.2rem"
                    size="20"
                    @click="addLayer('img')"
                  />
                  +
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Add Text')"
                    icon="TypeIcon"
                    role="button"
                    size="20"
                    style="margin-left: -0.2rem"
                    @click="addLayer('txt')"
                  />
                </div>
              </div>
              <b-list-group class="mt-1">
                <b-list-group-item
                  v-for="(layer, layerIndex) in options.document.content"
                  :key="layer.idx"
                  :class="[
                    'qr-layer',
                    layer.selected && 'qr-layer_selected',
                    (layer.img || layer.txt) && !(layer.selected && rename_layer) && 'pl-25'
                  ]"
                  @click.stop="updateDocument(layer, 'select')"
                >
                  <div class="d-flex align-items-center">
                    <b-form-input
                      v-if="layer.selected && rename_layer"
                      v-model="layer.name"
                      size="sm"
                      autofocus
                      @change="rename_layer = false"
                      @blur="rename_layer = false"
                      @keyup.esc="rename_layer = false"
                      @keyup.enter="rename_layer = false"
                      @input="updateTxt"
                    />
                    <small
                      v-else
                      :class="['qr-layer__name', layer.hidden ? 'qr-layer__name_line-through' : null]"
                    >
                      <feather-icon
                        v-if="layer.img || layer.txt"
                        :icon="layer.img ? 'ImageIcon' : 'TypeIcon'"
                        size="15"
                        style="margin-left: -0.1rem"
                      />
                      {{ layer.name }}
                    </small>
                    <div
                      v-if="!layer.background && (!layer.selected || !rename_layer)"
                      class="qr-layer__buttons"
                    >
                      <feather-icon
                        v-if="layerIndex > 1"
                        icon="ArrowUpIcon"
                        role="button"
                        :class="[!layer.selected && 'text-primary']"
                        @click.stop="updateDocument(layer, 'move', layerIndex, layerIndex - 1)"
                      />
                      <feather-icon
                        v-if="layerIndex > 0 && layerIndex < options.document.content.length - 2"
                        icon="ArrowDownIcon"
                        role="button"
                        :class="[!layer.selected && 'text-primary', 'ml-50']"
                        @click.stop="updateDocument(layer, 'move', layerIndex, layerIndex + 1)"
                      />
                      <feather-icon
                        :icon="!layer.hidden ? 'EyeIcon' : 'EyeOffIcon'"
                        role="button"
                        :class="[!layer.selected && 'text-primary', 'ml-50']"
                        @click.stop="updateDocument(layer, 'hide')"
                      />
                      <feather-icon
                        v-if="layerIndex > 0"
                        icon="TrashIcon"
                        role="button"
                        :class="[!layer.selected && 'text-danger', 'ml-50']"
                        @click.stop="updateDocument(layer, 'remove')"
                      />
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-overlay>
        </div>
      </b-col>
      <b-col>
        <div class="qr__sticky">
          <div
            v-show="!isFetching"
            id="preview"
            ref="preview"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <div class="qr__sticky">
          <template v-if="options.selected && options.selected.layer">
            <b-card v-if="options.selected.layer.background">
              <h6>{{ $t('qrLabelBackground') }}</h6>
              <b-form-group
                :label="$t('qrLabelTexture')"
                label-size="sm"
                class="mt-1 mb-0"
              >
                <div class="d-flex align-items-center flex-wrap">
                  <div
                    v-for="(icon, icon_key) in $options.textureIcons"
                    :key="icon_key"
                    v-svg-icon="icon"
                    :class="[
                      'qr__style-box',
                      options.selected.layer.background.pattern === icon_key ? 'qr__style-box_active' : null,
                    ]"
                    @click="updateBackground(icon_key)"
                  />
                </div>
              </b-form-group>
            </b-card>
            <b-card v-if="options.selected.layer.qr">
              <h6
                style="cursor: pointer"
                @click="qrSettingsVisible = !qrSettingsVisible"
              >
                {{ $t('qrLabelQrStyle') }}
                <feather-icon
                  :icon="qrSettingsVisible ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="18"
                  style="float:right"
                />
              </h6>
              <b-collapse
                id="qr-settings-collapse"
                v-model="qrSettingsVisible"
              >
                <b-form-group
                  :label="$t('qrLabelMargin')"
                  label-size="sm"
                  class="mt-1"
                >
                  <b-form-input
                    v-model.number="options.selected.layer.qr.margin"
                    type="number"
                    min="0"
                    max="999"
                    size="sm"
                    @change="updateQR"
                  />
                </b-form-group>
                <b-form-checkbox
                  v-model="options.selected.layer.qr.tag.attach"
                  size="sm"
                  class="mt-2"
                  @change="updateQR"
                >
                  {{ $t('qrLabelTag') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="options.selected.layer.qr.tag.attach"
                  v-model="options.selected.layer.qr.tag.prefix"
                  size="sm"
                  inline
                  class="ml-1"
                  @change="updateQR"
                >
                  {{ $t('qrLabelZone') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="options.selected.layer.qr.tag.attach && options.selected.layer.qr.tag.prefix"
                  v-model="options.selected.layer.qr.tag.prefix_short"
                  inline
                  size="sm"
                  @change="updateQR"
                >
                  {{ $t('qrLabelAbbreviation') }}
                </b-form-checkbox>
                <b-form-group
                  v-if="options.selected.layer.qr.tag.attach"
                  :label="$t('qrLabelTagFontSize')"
                  label-size="sm"
                  class="ml-1 mt-50"
                >
                  <b-form-input
                    v-model.number="options.selected.layer.qr.tag.font_size"
                    type="number"
                    min="1"
                    max="999"
                    size="sm"
                    @change="updateQR"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('qrLabelFinderFrame')"
                  label-size="sm"
                  class="mt-2"
                >
                  <div class="d-flex align-items-center flex-wrap">
                    <div
                      v-for="(qr_icon, icon_key) in $options.qrIcons.finder_frame"
                      :key="icon_key"
                      v-svg-icon="qr_icon"
                      :class="[
                        'qr__style-box',
                        options.selected.layer.qr.style.finder_frame === icon_key ? 'qr__style-box_active' : null,
                      ]"
                      @click="updateQR('finder_frame', icon_key)"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  :label="$t('qrLabelFinderEye')"
                  label-size="sm"
                >
                  <div class="d-flex align-items-center flex-wrap">
                    <div
                      v-for="(qr_icon, icon_key) in $options.qrIcons.finder_eye"
                      :key="icon_key"
                      v-svg-icon="qr_icon"
                      :class="[
                        'qr__style-box',
                        options.selected.layer.qr.style.finder_eye === icon_key ? 'qr__style-box_active' : null,
                      ]"
                      @click="updateQR('finder_eye', icon_key)"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  :label="$t('qrLabelPoints')"
                  label-size="sm"
                  class="mb-0"
                >
                  <div class="d-flex align-items-center flex-wrap">
                    <div
                      v-for="(qr_icon, icon_key) in $options.qrIcons.point"
                      :key="icon_key"
                      v-svg-icon="qr_icon"
                      :class="[
                        'qr__style-box',
                        options.selected.layer.qr.style.point === icon_key ? 'qr__style-box_active' : null,
                      ]"
                      @click="updateQR('point', icon_key)"
                    />
                  </div>
                </b-form-group>
              </b-collapse>
            </b-card>
            <b-card v-if="options.selected.layer.img">
              <h6>{{ $t('qrLabelImages') }}</h6>
              <b-form-group
                class="mt-1"
                :label="$t('qrLabelImagesCustom')"
                label-size="sm"
              >
                <b-form-file
                  size="sm"
                  class="mb-1"
                  accept="image/png, image/jpeg, image/svg+xml"
                  @input="onInputImageFile"
                />
                <div
                  class="d-flex align-items-center flex-wrap overflow-auto"
                  style="max-height: calc(52px * 2 + 7px * 2)"
                >
                  <div
                    v-for="image in options.userMedia"
                    :key="image.id"
                    v-svg-icon="image"
                    :class="[
                      'qr__style-box',
                      options.selected.layer.img.file_name === image.name ? 'qr__style-box_active' : null,
                    ]"
                    @click.stop="onClickImage(image)"
                  >
                    <b-spinner
                      v-if="!image.data"
                      variant="primary"
                      small
                    />
                    <b-badge
                      v-if="image.data"
                      variant="danger"
                      @click.stop="deleteImage(image)"
                    >
                      <feather-icon
                        icon="XIcon"
                        role="button"
                      />
                    </b-badge>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                :label="$t('qrLabelImagesLibrary')"
                label-size="sm"
                class="mb-0"
              >
                <div
                  class="d-flex align-items-center flex-wrap overflow-auto"
                  style="max-height: calc(52px * 2)"
                >
                  <div
                    v-for="(image, icon_key) in $options.commonImageIcons"
                    :key="icon_key"
                    v-svg-icon="image"
                    :class="[
                      'qr__style-box',
                      options.selected.layer.img.file_name === icon_key ? 'qr__style-box_active' : null,
                    ]"
                    @click="onClickLibraryImage(icon_key)"
                  />
                </div>
              </b-form-group>
            </b-card>
            <b-card v-if="options.selected.layer.txt">
              <font-select
                v-model="options.selected.layer.txt.font"
                size="sm"
                @input="changeFont"
              />
            </b-card>
            <b-card
              v-if="options.selected.layer.color"
            >
              <b-row
                v-if="options.selected.layer.color.fill"
                class="align-items-center"
              >
                <b-col cols="8">
                  <b-form-group
                    :label="options.selected.layer.color.stroke ? $t('qrLabelColorFill') : $t('qrLabelColor')"
                    label-size="sm"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="
                          options.selected.layer.color.fill"
                        type="color"
                        size="sm"
                        @input="updateColor"
                      />
                      <b-form-input
                        v-if="options.selected.layer.color.fillOpacity !== undefined"
                        v-model.number="options.selected.layer.color.fillOpacity"
                        v-b-tooltip.hover.top="$t('qrTooltipFillOpacity')"
                        type="number"
                        min="0"
                        max="1"
                        :step="options.selected.layer.color.fillOpacity <= 0.1 ? 0.01 : 0.1"
                        size="sm"
                        @change="updateColor"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="options.selected.layer.color.fill && options.selected.layer.color.stroke
                    && options.selected.layer.color.fill !== options.selected.layer.color.stroke"
                  cols="4"
                >
                  <b-button
                    class="btn-icon ml-1"
                    variant="outline-primary"
                    @click="swapColors"
                  >
                    <feather-icon icon="RotateCcwIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="options.selected.layer.color.stroke"
                class="align-items-center"
              >
                <b-col cols="12">
                  <b-form-group
                    :label="$t('qrLabelStroke')"
                    label-size="sm"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="options.selected.layer.color.stroke"
                        type="color"
                        size="sm"
                        @input="updateColor"
                      />
                      <b-form-input
                        v-if="options.selected.layer.color.strokeOpacity !== undefined"
                        v-model.number="options.selected.layer.color.strokeOpacity"
                        v-b-tooltip.hover.top="$t('qrTooltipStrokeOpacity')"
                        type="number"
                        min="0"
                        max="1"
                        :step="options.selected.layer.color.strokeOpacity <= 0.1 ? 0.01 : 0.1"
                        size="sm"
                        @change="updateColor"
                      />
                      <b-form-input
                        v-if="options.selected.layer.color.strokeWidth !== undefined"
                        v-model.number="options.selected.layer.color.strokeWidth"
                        v-b-tooltip.hover.top="$t('qrTooltipStrokeWidth')"
                        type="number"
                        min="0"
                        step="1"
                        size="sm"
                        @change="updateColor"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('qrLabelOpacity')"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.color.opacity"
                      type="number"
                      min="0"
                      max="1"
                      size="sm"
                      :step="options.selected.layer.color.opacity <= 0.1 ? 0.01 : 0.1"
                      @input="updateColor"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              v-if="options.selected.layer.frame"
            >
              <h6>
                {{ $t('qrLabelItemFrame') }}
                <b-form-checkbox
                  v-model="options.selected.layer.frame.active"
                  switch
                  inline
                  size="sm"
                  style="float:right"
                  @change="updateItemFrame"
                />
              </h6>
              <b-row
                v-if="options.selected.layer.frame.active === true"
              >
                <b-col>
                  <b-form-group
                    :label="$t('qrLabelWidth')"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.frame.width"
                      type="number"
                      min="0"
                      size="sm"
                      @change="updateItemFrame"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="$t('qrLabelItemFrameOffset')"
                    label-size="sm"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.frame.offset"
                      type="number"
                      size="sm"
                      @change="updateItemFrame"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-card>
            <b-card
              v-if="options.selected.layer.transform"
              v-b-tooltip.hover.left="$t('qrTooltipShortcuts')"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    label="X"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.transform.x"
                      type="number"
                      size="sm"
                      @change="transform"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Y"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.transform.y"
                      type="number"
                      size="sm"
                      @change="transform"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-form-group
                    :label="$t('qrLabelRotate')"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.transform.angle"
                      type="number"
                      size="sm"
                      @change="transform"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="$t('qrLabelScale')"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model.number="options.selected.layer.transform.scale"
                      type="number"
                      size="sm"
                      @change="transform"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="options.selected.layer.transform.fillNotFit !== undefined">
                  <div class="d-flex align-items-center mt-1 pt-75">
                    <b-form-radio
                      v-model="options.selected.layer.transform.fillNotFit"
                      :value="false"
                      size="sm"
                      @change="transform"
                    >
                      {{ $t('qrLabelFit') }}
                    </b-form-radio>
                    <b-form-radio
                      v-model="options.selected.layer.transform.fillNotFit"
                      :value="true"
                      class="ml-2"
                      size="sm"
                      @change="transform"
                    >
                      {{ $t('qrLabelFill') }}
                    </b-form-radio>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </div>
      </b-col>
    </b-row>
    <modal-qr-templates
      v-model="isModalQrTemplate"
      :qr-data="mockData()"
      :user-media="options.userMedia"
      :user-documents="documents"
      :document-idx="documentIdx || {}"
      @changeTemplate="loadTemplate"
      @updateUserDocuments="(docs) => { documents = docs; onClickSave(false) }"
    />
    <modal-qr-print v-model="isModalQrPrint" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BInputGroup,
  BDropdown,
  BDropdownForm,
  BFormFile,
  BSpinner,
  BOverlay,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import mixinQr from '@/mixins/qr'
import { createElementSVG, convertSize } from '@/qr/utils'
import { unselectElement } from '@/qr/qrcode'
import { qrStyleIcons } from '@/qr/qrcodeGen'
import { getTextureIcons } from '@/qr/background'
import getImageIcons from '@/qr/images'
import ModalQrTemplates from '@/components/modal/ModalQrTemplates.vue'
import ModalQrPrint from '@/components/modal/ModalQrPrint.vue'
import PaperFormatSelector from '@/components/PaperFormatSelector.vue'
import FontSelect from '@/components/FontSelect.vue'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  name: 'Qr',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BInputGroup,
    BDropdown,
    BDropdownForm,
    BFormFile,
    BSpinner,
    BOverlay,
    BBadge,
    ModalQrTemplates,
    ModalQrPrint,
    PaperFormatSelector,
    FontSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'svg-icon': {
      bind: async (el, binding) => {
        const { value } = binding
        const svg = createElementSVG('svg')
        el.prepend(svg)
        /* eslint-disable no-param-reassign */
        if (typeof value !== 'object' || value.isSvg) {
          svg.outerHTML = value.data || value
        } else {
          const img = createElementSVG('image', { href: value.data })
          const title = createElementSVG('title')
          title.textContent = value.name
          svg.prepend(title)
          const i = new Image()
          i.src = value.data
          await img.decode()
          svg.setAttribute('viewBox', `0 0 ${i.naturalWidth} ${i.naturalHeight}`)
          svg.appendChild(img)
        }
        /* eslint-enable no-param-reassign */
      },
    },
  },
  mixins: [mixinQr],
  qrIcons: qrStyleIcons(
    8, // qr_size
    1, // qr_margin
    null, // img_size
  ),
  textureIcons: getTextureIcons(64),
  commonImageIcons: getImageIcons(64),
  data() {
    return {
      imageSettingsVisible: true,
      qrSettingsVisible: true,
      isLoadedFromTemplate: false,
      isModalQrTemplate: false,
      isModalQrPrint: false,
      zoom_percent: 100,
    }
  },
  watch: {
    zoom_percent: {
      handler(newValue) {
        document.documentElement.style.setProperty('--qr-preview_svg-scale', newValue / 100)
      },
      immediate: true,
    },
  },
  created() {
    this.setData()
  },
  mounted() {
    document.addEventListener('keydown', e => {
      const tr = this.options.selected?.layer?.transform
      if (tr && e.target.tagName !== 'INPUT' && !this.rename_layer) {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
          if (e.shiftKey) {
            if (e.key === 'ArrowUp') tr.scale += 1
            else if (e.key === 'ArrowDown') tr.scale -= 1
            else if (e.key === 'ArrowLeft') tr.angle -= 1
            else if (e.key === 'ArrowRight') tr.angle += 1
          } else if (!e.shiftKey) {
            if (e.key === 'ArrowUp') tr.y -= 1
            else if (e.key === 'ArrowDown') tr.y += 1
            else if (e.key === 'ArrowLeft') tr.x -= 1
            else if (e.key === 'ArrowRight') tr.x += 1
          }
          e.preventDefault()
          this.transform()
        } else if (['Backspace', 'Delete'].includes(e.key)) {
          e.preventDefault()
          const o = this.options
          if (
            o.selected.layer !== o.document.content[0]
            && o.selected.layer !== o.document.content[o.document.content.length - 1]
          ) {
            this.updateDocument(this.options.selected.layer, 'remove')
          }
        }
      }
    })
    document.addEventListener('keyup', e => {
      if (e.target.tagName === 'INPUT' && ['Enter', 'Escape'].includes(e.key)) {
        e.target.blur()
      } else if (e.key === 'Escape') unselectElement(this.options.selected)
    })
  },
  beforeDestroy() {
    if (!this.options.isSaved && this.restaurant && isUserLoggedIn()) {
      this.onClickSave(false)
    }
  },
  methods: {
    onChangeImageFormat(event) {
      if (event.unitChanged) {
        this.options.document.image.bleedOrTrim = convertSize(
          this.options.document.image.bleedOrTrim,
          event.unitChanged.old,
          event.unitChanged.new,
        )
      }
      if (event.sizeChanged) {
        this.rerender = true
        this.options.isSaved = false
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'

  .qr
    .custom-radio.b-custom-control-sm,
    .custom-checkbox.b-custom-control-sm
      .custom-control-label
        &::before,
        &::after
          left: 0
    input[type=number]
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button
        appearance: auto
    &__sticky
      position: sticky
      top: calc(1.3rem + #{$navbar-height} + 2rem) // .content.app-content: padding-top, value
    &-layer
      display: flex
      flex-direction: column
      justify-content: center
      &__name
        flex-grow: 1
        width: 100%
        cursor: pointer
        white-space: pre
        text-overflow: ellipsis
        overflow-x: auto
        &_line-through
          text-decoration: line-through
      &__buttons
        display: none
        margin-left: 1.25rem
      &:hover
        .qr-layer__buttons
          display: flex
      &_selected
        background-color: $primary
        color: $white
        &.list-group-item
          &:hover
            background-color: $primary
    &__style-box
      border-radius: $border-radius
      border: 1px solid transparent
      cursor: pointer
      background-size: cover
      background-position: center
      background-repeat: no-repeat
      display: flex
      align-items: center
      justify-content: center
      position: relative
      width: 50px
      height: 50px
      & > svg
        height: 95%
        width: auto
      &_active
        border-color: $primary
      .badge
        position: absolute
        display: none
        top: 4px
        right: 4px
        padding: 1px
      &:hover
        .badge
          display: block

  #preview
    & > svg
      transform-origin: 0 0
      transform: scale(var(--qr-preview_svg-scale))
    g.selected > #handle
      fill: $primary
      stroke: $primary
    g.selectable:hover
      cursor: pointer
      outline-style: solid
      outline-color: $primary
      outline-width: var(--qr-element-outline-width, 1px)
    g[data-idx="background"].selectable:hover
      outline-offset: calc(0px - var(--qr-element-outline-width, 1px))
    g#grid-lines
      fill: none
      pointer-events: none
</style>
