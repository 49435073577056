<template>
  <b-modal
    id="modal-qr-templates"
    :visible="value"
    :title="$t('qrModalTemplateTitle')"
    centered
    size="xl"
    scrollable
    hide-footer
  >
    <template v-if="value">
      <div
        v-if="sortedUserTemplates.length"
        class="templates"
      >
        <div
          v-for="[idx,template] in sortedUserTemplates"
          :key="idx"
        >
          <b-card
            v-svg="getSvgElement(template)"
            no-body
            :class="[newTemplate.isUserDoc && newTemplate.idx === idx && 'selected']"
            @click.stop="newTemplate = { idx, isUserDoc: true }"
          >
            <div class="custom-badge">
              <feather-icon
                v-b-tooltip.hover.top="$t('Make a Copy')"
                icon="CopyIcon"
                role="button"
                variant="primary"
                @click.stop="cloneUserTemplate(idx)"
              />
              <feather-icon
                v-b-tooltip.hover.top="$t('Delete')"
                variant="primary"
                icon="TrashIcon"
                role="button"
                class="ml-1"
                @click.stop="deleteUserTemplate(idx)"
              />
            </div>
            <div
              v-if="documentIdx.isUserDoc && documentIdx.idx === idx"
              class="selected-label"
            >
              <span>{{ $t('Selected') }}</span>
            </div>
          </b-card>
          <b-button
            v-if="newTemplate.isUserDoc && newTemplate.idx === idx"
            variant="primary"
            @click="selectTemplate(template, idx, true)"
          >
            {{ $t('Select') }}
          </b-button>
        </div>
      </div>

      <hr>
      <h5 v-if="sortedUserTemplates.length">
        {{ $t('qrModalTemplateLibrary') }}
      </h5>
      <div class="templates">
        <div
          v-for="(template, idx) in $options.templateLibrary"
          :key="idx"
        >
          <b-card
            v-svg="template.svgElement"
            no-body
            :class="[!newTemplate.isUserDoc && newTemplate.idx === idx && 'selected']"
            @click.stop="newTemplate = { idx, isUserDoc: false }"
          >
            <div
              v-if="!documentIdx.isUserDoc && documentIdx.idx === idx"
              class="selected-label"
            >
              <span>{{ $t('Selected') }}</span>
            </div>
          </b-card>
          <b-button
            v-if="!newTemplate.isUserDoc && newTemplate.idx === idx"
            variant="primary"
            @click="selectTemplate(template, idx, false)"
          >
            {{ $t('Select') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { constructImage } from '@/qr/qrcode'
import templateLibrary from '@/qr/qrTemplates'

export default {
  name: 'ModalQrTemplates',
  templateLibrary,
  hasLibraryElements: null,
  components: {
    BCard,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    svg: {
      bind: async (el, binding) => {
        const svg = await binding.value
        el.appendChild(svg)
        const k = svg.viewBox.baseVal.width / svg.viewBox.baseVal.height
        el.style.width = `calc(var(--template-height) * ${k})` // eslint-disable-line
      },
    },
  },
  props: {
    value: { type: Boolean, required: true, default: false },
    qrData: { type: Object, required: true },
    userMedia: { type: Array, required: false, default: () => [] },
    userDocuments: { type: Object, required: true },
    documentIdx: { type: Object, required: true },
  },
  data() {
    return {
      newTemplate: { isUserDoc: null, idx: null },
      needUpdateDocuments: false,
      isCurrentDeleted: false,
      sortedUserTemplates: [],
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      if (!this.$options.hasLibraryElements) {
        Object.entries(this.$options.templateLibrary).forEach(([k, v]) => { this.$options.templateLibrary[k].svgElement = this.getSvgElement(v) })
        this.$options.hasLibraryElements = true
      }
      if (this.documentIdx.idx === undefined || this.documentIdx.isUserDoc === undefined) this.isCurrentDeleted = true
      this.sortedUserTemplates = Object.entries(this.userDocuments).sort((a, b) => b[1].lastUpdated - a[1].lastUpdated)
    })

    this.$root.$on('bv::modal::hide', () => {
      this.$emit('input', false)
      if (this.needUpdateDocuments) {
        this.needUpdateDocuments = false
        this.$emit('updateUserDocuments', this.userDocuments)
      }
      if (this.isCurrentDeleted) {
        this.isCurrentDeleted = false
        this.$emit('changeTemplate', this.pickAnotherTemplate())
      }
    })
  },
  methods: {
    pickAnotherTemplate() {
      let doc
      if (this.documentIdx.idx !== undefined) {
        doc = (this.documentIdx.isUserDoc ? this.userDocuments : templateLibrary)[this.documentIdx?.idx]
        if (doc) return { template: doc, isUserDoc: this.documentIdx.isUserDoc, idx: this.documentIdx.idx }
      }
      if (Object.keys(this.userDocuments).length) {
        const idx = this.sortedUserTemplates[0][0]
        doc = this.userDocuments[idx]
        return { template: doc, isUserDoc: true, idx }
      }
      const idx = Object.keys(templateLibrary)[0]
      return { template: templateLibrary[idx], isUserDoc: false, idx }
    },

    async getSvgElement(qrTemplate) {
      const opt = { document: qrTemplate, userMedia: this.userMedia }
      await this.$nextTick()
      return constructImage(this.qrData, opt, 'px', 'template')
    },

    deleteUserTemplate(idx) {
      delete this.userDocuments[idx]
      this.sortedUserTemplates = Object.entries(this.userDocuments).sort((a, b) => b[1].lastUpdated - a[1].lastUpdated)
      if (this.documentIdx.isUserDoc && this.documentIdx.idx === idx) this.isCurrentDeleted = true
      this.needUpdateDocuments = true
    },

    cloneUserTemplate(idx) {
      const newDoc = JSON.parse(JSON.stringify(this.userDocuments[idx]))
      const newIdx = Date.now().toString(36)
      newDoc.lastUpdated = this.userDocuments[idx].lastUpdated - 1
      this.userDocuments[newIdx] = newDoc
      this.sortedUserTemplates = Object.entries(this.userDocuments).sort((a, b) => b[1].lastUpdated - a[1].lastUpdated)
      this.needUpdateDocuments = true
    },

    selectTemplate(template, idx, isUserDoc) {
      if (isUserDoc !== this.documentIdx.isUserDoc || idx !== this.documentIdx.idx) {
        this.$emit('changeTemplate', {
          template: isUserDoc ? template : JSON.parse(JSON.stringify(template)), idx, isUserDoc,
        })
      }
      this.isCurrentDeleted = false
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/vue/libs/vue-select.scss'

#modal-qr-templates
  .modal-xl
    width: 94%
    max-width: 94%
    height: 94%
  .modal-content
    min-height: 100%
  h5
    margin-bottom: 1.5rem
  .templates
    display: flex
    flex-wrap: wrap
    --template-height: 18rem
    padding-bottom: 1rem
    div.card
      padding: 0
      height: var(--template-height)
      margin-right: 1rem
      margin-bottom: 1rem
      cursor: pointer
      overflow: hidden
      &.selected
        --template-height: 19rem
        outline: 2px solid $primary
      .custom-badge
        display: none
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        border: solid var(--primary)
        border-radius: 1rem
        padding: 1rem
        background-color: var(--white)
        svg.feather
          stroke: var(--primary)
      &:hover
        .custom-badge
          display: block
      &:not(.selected):hover
        outline: 1px solid $primary
      & div.selected-label
        width: 200px
        height: 20px
        position: absolute
        background-color: var(--primary)
        color: var(--white)
        transform: rotate(-45deg) translate(-60px, -15px)
        & span
          position: absolute
          display: block
          text-align: center
          width: 100%
          padding: 0
    button
      float: right
      width: calc(90% - 1rem)
      margin-right: calc(5% + 1rem)
</style>
