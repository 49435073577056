import svgShapes from '@/qr/svg-shapes.json'
import { createElementSVG } from './utils'

export default function getImageIcons(size = 64) {
  const icons = {}
  Object.keys(svgShapes).forEach(key => {
    const g = createElementSVG('g', { cursor: 'pointer' })
    g.innerHTML = svgShapes[key]
    const svg = g.firstElementChild
    const title = createElementSVG('title')
    title.textContent = key
    svg.prepend(title)
    svg.setAttribute('width', size.toString())
    svg.setAttribute('height', size.toString())
    icons[key] = svg.outerHTML
  })
  return icons
}
